const config = {
    url: {
        ACCOUNT_INSERT_RECORD: 'https://gtms-customer-portal-be.azurewebsites.net/account/insertrecord',
        ACCOUNT_UPDATE_FIELD: 'https://gtms-customer-portal-be.azurewebsites.net/account/updaterecord',
        FORGOT_PASSWORD: 'https://gtms-customer-portal-be.azurewebsites.net/password/forgotpassword',
        GET_AUDIT_CHART_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getauditchartdata',
        GET_AUDIT_SERVICE_DESC: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getauditsd',
        GET_BLOB_FILE: 'https://gtms-customer-portal-be.azurewebsites.net/blob/getblob',
        GET_BLOB_FILES: 'https://gtms-customer-portal-be.azurewebsites.net/blob/getblobfiles',
        GET_CHART_INFO: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getchartinfo',
        GET_COUNT_RECORDS: 'https://gtms-customer-portal-be.azurewebsites.net/data/getcountrecords',
        GET_COMPANIES: 'https://gtms-customer-portal-be.azurewebsites.net/account/getcompanies',
        GET_CUSTOM_QUERIES: 'https://gtms-customer-portal-be.azurewebsites.net/data/getcustomquery',
        GET_DASHBOARD_BUTTONS: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getdashboardbutton',
        GET_DASHBOARD_CHART_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getdashboardchart',
        GET_DOWNLOAD_REPORT: 'https://gtms-customer-portal-be.azurewebsites.net/file/downloadreport',
        GET_DOWNLOAD_AUDIT: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getauditdownload',
        GET_FILTERED_REPORT: 'https://gtms-customer-portal-be.azurewebsites.net/reports/getfilteredreport',
        GET_FILTER_AUDIT_CHART_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/chart/getfilteredauditchartdata',
        GET_FORM_ACCESSORIALS: 'https://gtms-customer-portal-be.azurewebsites.net/forms/getformaccessorials',
        GET_FORMS: 'https://gtms-customer-portal-be.azurewebsites.net/forms/getforms',
        GET_IP_ADDRESS: 'https://gtms-customer-portal-be.azurewebsites.net/auth/getip',
        GET_LANEVIEW_SHIPMENT: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/getshipment',
        GET_LANDING_PAGE: 'https://gtms-customer-portal-be.azurewebsites.net/auth/getlandingpage',
        GET_LANEVIEW_TOGGLE_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/toggle',
        GET_PAGE_ACCESS: 'https://gtms-customer-portal-be.azurewebsites.net/account/getpageaccess',
        GET_PAGINATION_SELECT_ALL: 'https://gtms-customer-portal-be.azurewebsites.net/data/getpaginationselect',
        GET_REMIT_FILES: 'https://gtms-customer-portal-be.azurewebsites.net/remits/getremits',
        GET_REMIT_SEARCH: 'https://gtms-customer-portal-be.azurewebsites.net/remits/searchremits',
        GET_REPORT: 'https://gtms-customer-portal-be.azurewebsites.net/reports/getreport',
        GET_REPORTS: 'https://gtms-customer-portal-be.azurewebsites.net/reports/getreports', 
        GET_ROLE_LIST: 'https://gtms-customer-portal-be.azurewebsites.net/account/getrolelist',
        GET_TRACKING_REPORTS: 'https://gtms-customer-portal-be.azurewebsites.net/tracking/gettrackingreports',
        GET_TRACKING_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/tracking/gettrackingdetails',
        GET_SAAS_DOMESTIC_COUNTRY: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/getdomesticcountry',
        GET_SAAS_CITIES: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/getcities',
        GET_SAAS_POSTAL: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/getpostals',
        GET_SAAS_STATE: 'https://gtms-customer-portal-be.azurewebsites.net/laneview/getstates',
        GET_SELECT_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/data/getselectdata',
        GET_USER_ACCOUNTS: 'https://gtms-customer-portal-be.azurewebsites.net/account/getaccounts',
        GET_USER_CREDS: 'https://gtms-customer-portal-be.azurewebsites.net/auth/usercreds',
        GET_WIDGET_DATA: 'https://gtms-customer-portal-be.azurewebsites.net/widget/getvalue',
        GET_WIDGET_DETAILS: 'https://gtms-customer-portal-be.azurewebsites.net/widget/getdetails',
        HOME_URL: 'https://gtms-customer-portal-be.azurewebsites.net',
        LOGIN_URL: 'https://gtms-customer-portal-be.azurewebsites.net/auth',
        OCEAN_TRACKING_FILTER: 'https://gtms-customer-portal-be.azurewebsites.net/tracking/oceantrackingfilter',
        OCEAN_TRACKING_SUBMIT: 'https://gtms-customer-portal-be.azurewebsites.net/tracking/oceantrackingcoordinates',
        OCEAN_TRACKING_DETAILS: 'https://gtms-customer-portal-be.azurewebsites.net/tracking/oceantrackingdetails',
        RESET_PASSWORD: 'https://gtms-customer-portal-be.azurewebsites.net/password/resetpassword',
        SEARCH_BLOB_CONTAINER: 'https://gtms-customer-portal-be.azurewebsites.net/blob/searchBlob',
        SEARCH_PANEL_FILTER: 'https://gtms-customer-portal-be.azurewebsites.net/chart/searchfilterpanel',
        SUBMIT_BIOVATIONS_DOMESTIC_FORM: 'https://gtms-customer-portal-be.azurewebsites.net/forms/submitbiovationsdomestic',
        SUBMIT_CSV_FORM: 'https://gtms-customer-portal-be.azurewebsites.net/forms/submitcsvform',
        SUBMIT_FORM : 'https://gtms-customer-portal-be.azurewebsites.net/forms/submitform',
        SUBMIT_LOAD_QUOTE: 'https://gtms-customer-portal-be.azurewebsites.net/forms/submitloadquote',
        SUBMIT_ENHANCEMENT: 'https://gtms-customer-portal-be.azurewebsites.net/forms/submitenhancement',
        TEMP_FILE_UPLOAD: 'https://gtms-customer-portal-be.azurewebsites.net/file/uploadtempfile',
        USER_REGISTER: 'https://gtms-customer-portal-be.azurewebsites.net/auth/register',
        VERIFY_ADMIN: 'https://gtms-customer-portal-be.azurewebsites.net/auth/verifyadmin',
        VERIFY_TOKEN: 'https://gtms-customer-portal-be.azurewebsites.net/auth/verifytoken',
   }
};

export default config