import CustomizedTitle from '../../../components/customizedTitle/CustomizedTitle';
import ToggleButton from '../../../components/common/ToggleButton';
import ListURL from '../../../components/common/lists/ListURL';
import CircularProgress from '@mui/material/CircularProgress';
import SelectBox from '../../../components/common/SelectBox';
import SearchBar from '../../../components/common/SearchBar';
import DataGrid from '../../../components/tables/DataGridAG';
import Table from '../../../components/tables/dynamicTable';
import Sidebar from '../../../components/sidebar/Sidebar';
import verifyToken from "../../../utils/tokenUtils.js";
import NavBar from '../../../components/navbar/NavBar';
import pageTitle from '../../../utils/pageTitles.json';
import fieldMap from '../../../utils/fieldMap.json';
import { config } from '../../../config/Constants';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import './shipmenttrack.css';
import Axios from 'axios';

const ShipmentTrack = () => {
  const pageId = 11;
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [toggle, setToggle] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [tableSelect, setTableSelect] = useState('');
  const [toggleData, setToggleData] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleName, setTitleName] = useState(null);
  const [fieldName, setFieldsName] = useState([]);

  const productDetails = { data: toggleData }
  const trackingDetails = { data: toggleData }
  const podObject = { data: toggleData, listType: 'POD' }
  const bolObject = { data: toggleData, listType: 'BOL' }
  const searchConfig = { size: 'normal', keyPress: true }
  const dataObject = { data: rows, rowSelection: 'single', }
  const titleObject = {title: titleName}
  const selectObject = {label: 'Search By', menuItems: fieldName}

  const getRole = () => { return Cookies.get('cp-role') };
  const getCompany = () => { return Cookies.get('cp-cn'); }
  const getAuthToken = () => { return Cookies.get('gtms-cp-auth'); };
  const handleSelectValue = (selectedValue) => { setSelectValue(selectedValue); }
  const inputValue = (value) => { setSearchValue(value); }

  const getSelectFields= () => {
    const company = getCompany();
    const selectObject = fieldMap[company]['Shipment Track']['Select Fields']
    setFieldsName(selectObject)
  }

  const toggleObject = {
    toggleButtons: ['Product Details', 'Tracking Details', 'POD Attachment', 'BOL Attachment' ],
    rows: rows,
    tableSelection: tableSelect,
  }

  const getTitle = () => {
    const company = getCompany();
    const title = pageTitle[company]['Shipment Track']
    setTitleName(title)
  }

  useEffect(() => {
    getTitle();
    const token = getAuthToken();
    token ? verifyToken(token) : navigate('/');
    const role = getRole();
    if (role == 'VENDOR') { navigate('/formslanding') };
    getSelectFields();
  }, []);

  const onSearch = (value) => {
    setSearchValue(value);
    handleSubmit();
  }

  const tableSelection = (value) => {
    setToggle(null);
    setTableSelect('');
    setTableSelect(value);
  }

  const handleSubmit = async() => {
    setIsLoading(true);
    const shipmentDataObject = await getShipmentTableData();
    await displayData(shipmentDataObject);
    console.log(shipmentDataObject);
    setToggleData({});
    setToggle('');
    setIsLoading(false);
  }

  const getShipmentTableData = async() => {
    const company = getCompany();
    const response = await Axios.get(config.url.GET_LANEVIEW_SHIPMENT, {
      params: {
        selectValue: selectValue,
        searchValue: searchValue,
        company: company,
      }
    });
    return response.data;
  }

  const displayData = async(shipmentDataObject) => {
    if (shipmentDataObject.data && shipmentDataObject.data.length !== 0) {
      setRows(shipmentDataObject.data);
    } else {
      setRows([]);
    }
  }

  const toggleValue = async(value) => {
    setToggle(value);
    console.log("Toggle Value:" + value)
    const data = await getTableToggle(value);
    setToggleData(data);
  }

  const getTableToggle = async(toggle) => {
    const response = await Axios.get(config.url.GET_LANEVIEW_TOGGLE_DATA, {
      params: {
        toggle: toggle,
        tableRow: tableSelect,
      }
    });
    return response.data.data;
  }

  return (
    <div className="LandingPage">
      <div className="LandingPageContainer">
        <NavBar/>
        <Sidebar/>
        <div className="laneviewPageContent">
          <div className="laneviewPageContentTop">
            <CustomizedTitle titleObject={titleObject}/>
          </div>
          <div className="laneviewPageContentMid">
            <div className="laneviewSearching">
              <div className="laneviewSelectBox">
                <SelectBox selectValue={handleSelectValue} selectObject={selectObject}/>
              </div>
              <div className="laneviewSearch">
                <SearchBar inputValue={inputValue} onSearch={onSearch} config={searchConfig}/>
              </div>
              <div className="lanviewSubmitSearch">
                <Button onClick={handleSubmit} variant='contained' disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </div>
            </div>
            <div className="laneviewRow2">
              <div className="laneviewShipmentBoard">
                  <DataGrid dataObject={dataObject} tableSelection={tableSelection}/>
              </div>
            </div>
          </div>
          {rows.some(row => Object.keys(row).length > 0) ? (
            <div className="laneviewPageContentBottom">
              <div className="laneviewToggle">
                <ToggleButton 
                  onClick={getTableToggle}
                  toggleValue={toggleValue}
                  toggleObject={toggleObject} />
              </div>
              <div className="laneviewDetailTables">
                {toggle == 'Product Details' ? (
                  <div className="laneviewProductDetails">
                    <Table dataObject={productDetails}/>
                  </div>
                  ) : toggle == 'Tracking Details' ? (
                  <div className="laneviewTrackingDetails">
                    <Table dataObject={trackingDetails}/>
                  </div>
                ) : toggle == 'POD Attachment' ? (
                  <div className='laneviewPodList'>
                    <ListURL listObject={podObject}/>
                  </div>
                ) : toggle == 'BOL Attachment' ? (
                  <div className='laneviewBolList'>
                    <ListURL listObject={bolObject}/>
                  </div>
                ) : (<div> </div>)}
              </div>
            </div>
          ) : (<div> </div>)}
        </div>
      </div>
    </div>
  )
}

export default ShipmentTrack